<template lang="pug">
.full-box.main-box(v-loading="loading")
  .customer-box
    label.blue-header 对账信息
    .info-box
      DataInfo(
        v-if="show"
        :data="recondData"
        :fields="recondFormFields")
  .bgc-block
  .right-box
    .page-table-box
      .blue-header 对账明细
      DataTable.page-table(
        v-if="reconId && show"
        ref="dataTable"
        keyName="table"
        serverSide
        highlightClickRow
        resource="/chargingReconDetail/list"
        :needFilterMargin="false"
        :extraFilterBy="{reconId}"
        :filterFields="filterFields"
        :columns="columns"
        :operateButtonList="operateButtonList"
        :operationWidth="80"
        :cellClassNameFunc="processResultClassFunc"
        @current-change="handleCurrentChange")
    .bgc-block-cross
    BillTable(:chargingNo="currentChargingNo")
</template>

<script>
import { mapActions } from 'vuex'
import BillTable from '../components/BillTable.vue'
import { processResultClassFunc } from '@/utils/common.js'

export default {
  name: 'ChargingReconDetail',
  components: { BillTable },
  watch: {
    reconId () {
      this.getReconData()
      this.getDetailData()
      this.currentChargingNo = ''
    }
  },
  computed: {
    reconId () {
      return this.$route.query.reconId
    }
  },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'handle', url: '/billManage/chargingRecon/handle' }
    ])
    return {
      show: false,
      recondData: {},
      loading: false,
      processResultClassFunc,
      recondFormFields: [
        { name: 'reconTime', label: '对账日期', render: { type: 'date' } },
        { name: 'reconStatus', label: '对账状态', relation: 'reconStatus', render: { type: 'select' } },
        {
          name: 'billCount',
          label: '缴费账单数',
          width: 150,
          render: (value, field, data) => {
            const { billCountReconBefore, billCountReconAfter } = data
            return `${billCountReconBefore || '-'}笔/${billCountReconAfter || '-'}笔(应对/实对)`
          }
        },
        {
          name: 'billAmount',
          label: '缴费金额',
          width: 150,
          render: (value, field, data) => {
            const { billAmountReconBefore, billAmountReconAfter } = data
            return `${billAmountReconBefore || '-'}元/${billAmountReconAfter || '-'}元(应对/实对)`
          }
        },
        { name: 'name', label: '收款账户' },
        { name: 'accountNo', label: '收款账号' },
        { name: 'collectionNo', label: '商户号' },
        { name: 'terminalNo', label: '终端号' },
        { name: 'orgNo', label: '机构号' }
      ],
      filterFields: [{
        name: 'reconResult',
        label: '对账结果',
        form: {
          tag: 'select',
          relation: 'reconResult',
          clearable: true
        }
      }, {
        name: 'processResult',
        label: '处理结果',
        form: {
          tag: 'select',
          relation: 'processResult',
          clearable: true
        }
      }, {
        name: 'reconId',
        label: 'id',
        showable: false,
        form: {
          tag: 'input'
        }
      }],
      columns: [
        { name: 'reconResult', label: '对账结果', minWidth: 100, relation: 'reconResult', render: { type: 'select' } },
        { name: 'chargingNo', label: '缴费号', minWidth: 160 },
        { name: 'billCount', label: '账单数', minWidth: 90 },
        { name: 'billAmount', label: '账单金额', minWidth: 90 },
        { name: 'reconAmount', label: '对账金额', minWidth: 90 },
        { name: 'usewaterCode', label: '用水户号', minWidth: 120 },
        {
          name: 'customerName',
          label: '用户',
          width: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName || '-'}(${customerCode || '-'})`
          }
        },
        { name: 'chargingTime', label: '缴费时间', minWidth: 140, render: { type: 'date', timeType: 'YYYY-MM-DD HH:mm' } },
        { name: 'processResult', label: '处理结果', minWidth: 120, relation: 'processResult', render: { type: 'select' } }
      ],
      handleType: new Map([
        ['SYSTEM', 'processSystem'], // 系统单边账
        ['AGENCY', 'processAgency'] // 银行单边账
      ]),
      operateButtonList: [{
        label: '处理',
        name: 'handle',
        type: 'text',
        textType: 'primary',
        showabled: (data) => actionRights.handle && data.reconResult && data.processResult === 'UNPROCESS',
        func: ({ data }) => {
          return this.handleReconError({
            type: this.handleType.get(data.reconResult),
            detailId: data.id
          })
        }
      }],
      currentChargingNo: null
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getDetailData () {
      this.$refs.dataTable.reRenderTableData()
      this.$nextTick(() => {
        this.$refs.dataTable.setCurrentRow()
      })
    },
    renderRelation () {
      this.getRelations(['reconResult', 'processResult', 'reconStatus'])
        .then(res => {
          this.$renderRelationColumns(res, [this.columns, this.filterFields, this.recondFormFields])
          this.$nextTick(() => { this.show = true })
        })
    },
    getReconData () {
      if (!this.reconId) return
      this.loading = true
      this.$get({
        url: `/chargingRecon/get/${this.reconId}`
      })
        .then(res => {
          this.loading = false
          if (!res) return
          this.recondData = res.data
        })
        .catch(e => {
          this.loading = false
        })
    },
    // processAgency银行单边账：核销账单-将账单置为已收费
    // processSystem系统单边账：销账账单-将账单置为未缴费
    handleReconError ({ type, detailId }) {
      const url = `/chargingReconDetail/${type}/${detailId}`
      return new Promise((resolve, reject) => {
        this.$patch({ url })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('处理完成')
            this.getDetailData()
            this.currentChargingNo = ''
          })
          .catch(reject)
      })
    },
    handleCurrentChange (row) {
      this.currentChargingNo = row ? row.chargingNo : null
    }
  },
  mounted () {
    this.getReconData()
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.main-box
  display: flex
  flex-direction: row
  .customer-box
    width: 280px
    padding: 8px
    .info-box
      height: calc( 100% - 40px )
      overflow-y: auto
      padding-top: 8px
  .right-box
    width: calc( 100% - 288px )
    height: 100%
    .page-table-box
      height: calc( 100% - 308px )
      width: 100%
      padding-top: 8px
      .blue-header
        margin-left: 8px
      .page-table
        height: calc( 100% - 28px )
        width: 100%
  .bgc-block
    width: 8px
    height: 100%
    background-color: #eceff0
  .bgc-block-cross
    height: 8px
    width: 100%
    background-color: #eceff0
</style>
