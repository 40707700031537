<template lang="pug">
.inner-box.full-width
  .blue-header 缴费账单
  .main-content
    .empty(v-if="!chargingNo") 请先选择一条对账明细
    DataTable.full-box(
      v-else-if="showTable"
      ref="table"
      :serverSide="false"
      :loading="loading"
      :data="tableData"
      :showFilter="false"
      :hasPage="false"
      :hasFooter="false"
      :settingButtonList="[]"
      :filterFields="[]"
      :cellClassNameFunc="billPayStatusClassFunc"
      :columns="columns")
</template>

<script>
import { mapActions } from 'vuex'
import { billPayStatusClassFunc } from '@/utils/common.js'

export default {
  name: 'BillTable',
  props: {
    chargingNo: {
      type: [String, Number],
      default: null
    }
  },
  watch: {
    chargingNo (chargingNo) {
      this.getData(chargingNo)
    }
  },
  data () {
    return {
      showTable: false,
      loading: false,
      tableData: [],
      billPayStatusClassFunc,
      columns: [
        { name: 'billMonth', label: '账期', minWidth: 80 },
        { name: 'billNo', label: '账单编号', minWidth: 130 },
        { name: 'usewaterCode', label: '用水户号', minWidth: 100 },
        {
          name: 'customerName',
          label: '用户',
          width: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName || '-'}(${customerCode || '-'})`
          }
        },
        { name: 'quantity', label: '水量' },
        { name: 'totalAmount', label: '总金额', minWidth: 100 },
        { name: 'billPayStatus', label: '缴费状态', relation: 'billPayStatus', render: { type: 'select' } },
        { name: 'payType', label: '缴费方式', relation: 'payType', render: { type: 'select' } },
        { name: 'payTime', label: '缴费日期', minWidth: 120, render: { type: 'date' } },
        { name: 'waterAmount', label: '水费总额', minWidth: 100 },
        { name: 'sewageAmount', label: '污水费总额', minWidth: 100 },
        { name: 'waterResourceAmount', label: '水资源费总额', minWidth: 100 },
        { name: 'penaltyAmount', label: '滞纳金总额', minWidth: 100 },
        { name: 'penaltyDays', label: '滞纳天数' },
        { name: 'billCreateTime', label: '账单产生日期', minWidth: 120, render: { type: 'date' } }
      ]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['billPayStatus', 'payType'])
        .then(res => {
          this.$renderRelationColumns(res, [this.columns])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    getData (chargingNo) {
      return new Promise((resolve, reject) => {
        if (!chargingNo) {
          this.tableData = []
          return
        }
        this.loading = true
        this.$get({
          url: `/charging/findBillsByChargingNo?chargingNo=${chargingNo}`
        })
          .then(res => {
            this.loading = false
            if (!res) {
              reject(res)
              return
            }
            this.$set(this, 'tableData', res.data || [])
            this.$refs.table && this.$refs.table.updateAllTableData(res.data)
            resolve()
          })
          .catch(e => {
            this.loading = false
            reject(e)
          })
      })
    }
  },
  mounted () {
    this.renderRelation()
    this.getData(this.chargingNo)
  }
}
</script>

<style lang="sass" scoped>
.inner-box
  height: 300px
  padding-top: 8px
  .blue-header
    margin-left: 8px
  .main-content
    width: 100%
    height: calc( 100% - 28px )
</style>
